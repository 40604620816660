import React from 'react'

import { DatabaseSKU } from '../../../api-client'

import { BaseSKUsTable, Container, NameLabel, SKUsTableBodyRow, SKUsTableData, SKUsTableHeader } from './style'

export interface SKUsTableProps {
  /**
   * SKUの一覧
   */
  skus: Array<DatabaseSKU>
}

export const SKUsTable: React.FC<SKUsTableProps> = ({ skus }) => {
  return (
    <Container>
      <BaseSKUsTable>
        <thead>
          <tr>
            <SKUsTableHeader width={170}>JANコード</SKUsTableHeader>
            <SKUsTableHeader width={250}>商品名</SKUsTableHeader>
            <SKUsTableHeader width={77}>Kg/ctn</SKUsTableHeader>
            <SKUsTableHeader width={77}>M3/ctn</SKUsTableHeader>
            <SKUsTableHeader width={77}>ケース入数</SKUsTableHeader>
            <SKUsTableHeader width={77}>新規商品</SKUsTableHeader>
            <SKUsTableHeader width={77}>廃番</SKUsTableHeader>
            <SKUsTableHeader width={250}>MOQ管理グループ</SKUsTableHeader>
            <SKUsTableHeader width={77}>MOQ</SKUsTableHeader>
            <SKUsTableHeader width={202}>発注グループ</SKUsTableHeader>
            <SKUsTableHeader width={250}>取引先コード</SKUsTableHeader>
            <SKUsTableHeader width={250}>FCL/LCL</SKUsTableHeader>
            <SKUsTableHeader width={145}>物流パターン</SKUsTableHeader>
            <SKUsTableHeader width={56}>部門</SKUsTableHeader>
            <SKUsTableHeader width={54}>生産LT</SKUsTableHeader>
            <SKUsTableHeader width={108}>入庫停止日</SKUsTableHeader>
            <SKUsTableHeader width={145}>積地港</SKUsTableHeader>
            <SKUsTableHeader width={145}>担当者名</SKUsTableHeader>
            <SKUsTableHeader width={77}>安全在庫週数</SKUsTableHeader>
            <SKUsTableHeader width={30}></SKUsTableHeader>
          </tr>
        </thead>
        <tbody>
          {skus.map((sku) => {
            const name = [sku.name, sku.specName].filter((e) => !!e).join('・')
            return (
              <SKUsTableBodyRow key={sku.code}>
                <SKUsTableData>{sku.code}</SKUsTableData>
                <SKUsTableData>
                  <NameLabel>{name.length > 30 ? `${name.slice(0, 30)}...` : name}</NameLabel>
                </SKUsTableData>
                <SKUsTableData>{sku.loadCapacityPerCarton}</SKUsTableData>
                <SKUsTableData>{sku.capacityPerCarton}</SKUsTableData>
                <SKUsTableData>{sku.quantityPerCase}</SKUsTableData>
                <SKUsTableData>{sku.typeOfItem}</SKUsTableData>
                <SKUsTableData>{sku.discontinued}</SKUsTableData>
                <SKUsTableData>
                  <NameLabel>{sku.moqGroupName}</NameLabel>
                </SKUsTableData>
                <SKUsTableData>
                    {sku.moq != null ? sku.moq : '-'}</SKUsTableData>
                <SKUsTableData>{sku.orderGroupName}</SKUsTableData>
                <SKUsTableData>{sku.supplierCode ?? '-'}</SKUsTableData>
                <SKUsTableData>{sku.fclLcl}</SKUsTableData>
                <SKUsTableData>{sku.logisticPattern.name}</SKUsTableData>
                <SKUsTableData>{sku.departmentCode != null ? sku.departmentCode : '-'}</SKUsTableData>
                <SKUsTableData>{sku.leadtime}</SKUsTableData>
                <SKUsTableData>
                  {sku.inventoryUnitReceivingsStopDate == null
                    ? '-'
                    : `${sku.inventoryUnitReceivingsStopDate.getFullYear()}/${(
                        '00' +
                        (sku.inventoryUnitReceivingsStopDate.getMonth() + 1)
                      ).slice(-2)}/${('00' + sku.inventoryUnitReceivingsStopDate.getDate()).slice(-2)}`}
                </SKUsTableData>
                <SKUsTableData>{sku.portOfLoading}</SKUsTableData>
                <SKUsTableData>{sku.inCharge}</SKUsTableData>
                <SKUsTableData>{sku.safetyInventoryWeeks}</SKUsTableData>
                <SKUsTableData></SKUsTableData>
              </SKUsTableBodyRow>
            )
          })}
        </tbody>
      </BaseSKUsTable>
    </Container>
  )
}
